import { Controller } from "stimulus";
import * as Luxon from "luxon";

export default class extends Controller {
  static targets = ["lineContainer", "line", "start", "end"];

  timer = null;

  connect() {
    setTimeout(() => {
      this.redraw();
      this.timer = setInterval(() => {
        this.redraw();
      }, 60 * 1000);
    }, 100);
  }

  disconnect() {
    clearInterval(this.timer);
  }

  redraw() {
    try {
      const line = this.lineTarget;
      const start = this.startTarget;
      const end = this.endTarget;

      const orientation = this.data.get("orientation");

      const parentRect = this.lineContainerTarget.getBoundingClientRect();
      const startRect = start.getBoundingClientRect();
      const endRect = end.getBoundingClientRect();

      const fullWidth = endRect.right - startRect.left;
      const fullHeight = endRect.bottom - startRect.top;

      const startTime = this.data.get("startIso");
      const endTime = this.data.get("endIso");
      const timezone = this.data.get("timezone");
      const currentTime = Luxon.DateTime.local().setZone(timezone);
      const startDateTime = Luxon.DateTime.fromISO(startTime, {
        zone: timezone,
      });
      const endDateTime = Luxon.DateTime.fromISO(endTime, { zone: timezone });

      const percentage =
        (currentTime - startDateTime) / (endDateTime - startDateTime);

      if (percentage < 0 || percentage > 1) {
        line.classList.add("u-hidden");
        return;
      }

      const initialOffset =
        orientation === "horizontal" ? start.offsetLeft : start.offsetTop;
      const xPos =
        initialOffset + percentage * (endRect.right - startRect.left);
      const yPos =
        initialOffset + percentage * (endRect.bottom - startRect.top);

      if (orientation === "horizontal") {
        line.classList.add("Line--vertical");
      }

      line.classList.remove("u-hidden");
      line.style.left =
        orientation === "horizontal" ? `${xPos}px` : `${start.offsetLeft}px`;
      line.style.top =
        orientation === "horizontal" ? `${start.offsetTop}px` : `${yPos}px`;
      line.style.width =
        orientation === "horizontal" ? "2px" : `${fullWidth}px`;
      line.style.height =
        orientation === "horizontal" ? `${fullHeight}px` : "2px";
    } catch (e) {
      console.log(e);
    }
  }
}
